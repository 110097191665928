/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.search-empty {
  text-align: center;
  padding: 12px 0;
}
.search-empty-icon {
  font-size: 1.5em;
}
