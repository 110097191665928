/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.myselect-pagination {
  display: flex;
  align-content: center;
  border-top: 1px solid #d9d9d9;
}
.myselect-pagination .myselect-pagination-content {
  align-self: center;
  flex-grow: 1;
  text-align: center;
}
